import { MobileEventHandler } from "./MobileWrapperEventHandler";

import { fetchContactsByPhone } from "@tef-novum/webview-bridge";

/**
 * Handler for
 * @param payload @see ContactsByPhone
 * @returns Promise<void>
 */
export const handlefetchContactsByPhone: MobileEventHandler<
  "contacts-byphone"
> = async (payload) => {
  console.log("handlefetchContactsByPhone", payload);
return await fetchContactsByPhone(
  payload.phoneNumbers,
  ).then(res =>{
    console.log("if fetchContactsByPhone success", res);
  }).catch(err=>{
    console.log("if fetchContactsByPhone error",err);
  });
};
