import { MobileEventHandler } from "./MobileWrapperEventHandler";

import { downloadBase64 } from "@tef-novum/webview-bridge";

/**
 * Handler for
 * @param payload @see DownloadBase64Event
 * @returns Promise<void>
 */
export const handleDownloadBase64: MobileEventHandler<
  "download-base64"
> = async (payload) => {
  console.log("handleDownloadBase64", payload);
  //return downloadBase64({contentInBase64: string; fileName: string}) => Promise<void>;
return await downloadBase64({
    contentInBase64: payload.contentInBase64,
    fileName: payload.fileName,
  }).then(res =>{
    console.log(res);
  }).catch(err=>{
    console.log(err);
  });
};
