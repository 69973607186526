import { MobileEventNames } from "@mfe/mobile-wrapper-api";
import { BUS_PREFIX, MobileWrapperBusEventPayload } from "../bus";
import {
  handleCartUpdated,
  MobileEventHandler,
  handleCustomerBarred,
  handleDownloadBase64,
  handleDismissEvent,
  handlefetchContactsByPhone,
  handleLogout,
  handleOpenOnboarding,
} from "./handler";
import { handleSetTitle } from "./handler/handleSetTitle";

type EventMap = {
  [K in MobileEventNames as `${typeof BUS_PREFIX}${K}`]: CustomEvent<
    MobileWrapperBusEventPayload<K>
  >;
};

/**
 * Mapping from event to handler
 * This is the only thing that must be edited in here to map a new event to handler
 */
const eventHandlerMap: Record<keyof EventMap, MobileEventHandler<any>> = {
  "tef:mobile_wrapper:customer-barred": handleCustomerBarred, 
  "tef:mobile_wrapper:download-base64": handleDownloadBase64,
  "tef:mobile_wrapper:dismiss-trigger": handleDismissEvent,
  "tef:mobile_wrapper:contacts-byphone": handlefetchContactsByPhone,
  "tef:mobile_wrapper:cart-updated": handleCartUpdated,
  "tef:mobile_wrapper:logout": handleLogout,
  "tef:mobile_wrapper:set-title": handleSetTitle,
  "tef:mobile_wrapper:open-onboarding": handleOpenOnboarding,
} as const;

export const registerEventListeners = () => {
  for (const key in eventHandlerMap) {
    const eventName = key as keyof EventMap;
    // @ts-ignore
    const handlerFunction = eventHandlerMap[key];

    attachHandler(eventName, handlerFunction);
  }
};
declare global {
  // eslint-disable-next-line prettier/prettier
  interface WindowEventMap extends EventMap {}
}

const attachHandler = (
  eventName: keyof EventMap,
  handler: MobileEventHandler<any>
) => {
  window.addEventListener(
    eventName,
    async (evt: CustomEvent<MobileWrapperBusEventPayload<any>>) => {
      try {
        const res = await handler(evt.detail.mobileEventPayload);
        evt.detail.promiseHandle.resolve(res);
      } catch (e) {
        evt.detail.promiseHandle.reject(e);
      }
    },
    { capture: true }
  );
};
