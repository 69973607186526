import {
  EmitMobileWrapperEvent,
  MobileEventMap,
  MobileEventNames,
} from "@mfe/mobile-wrapper-api";

export const BUS_PREFIX = "tef:mobile_wrapper:" as const;

class PromiseHandleImpl {
  public promise: Promise<void>;
  constructor() {
    this.promise = new Promise<void>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  public resolve(success: void) {}
  public reject(error?: unknown) {}
}

export const emit: EmitMobileWrapperEvent = async (
  evt,
  mobileEventPayload
): Promise<any> => {
  const fqnEventName = `${BUS_PREFIX}${evt}`;
  const promiseHandle = new PromiseHandleImpl();
  console.log("emit", fqnEventName, mobileEventPayload);
  // todo: check for alternative
  window.dispatchEvent(
    new CustomEvent(fqnEventName, {
      detail: {
        mobileEventPayload,
        promiseHandle,
      },
    })
  );
  return promiseHandle.promise;
};

export type PromiseHandle = InstanceType<typeof PromiseHandleImpl>;

export type MobileWrapperBusEventPayload<E extends MobileEventNames> = {
  mobileEventPayload: MobileEventMap[E][0];
  promiseHandle: PromiseHandle;
};
