import { logout } from "@tef-novum/webview-bridge";
import { MobileEventHandler } from "./MobileWrapperEventHandler";

/**
 * Handler für @see LogouEvent
 * @param evt LogouEvent
 * @returns Promise<true> if logout was successful
 */
export const handleLogout: MobileEventHandler<"logout"> = async (
  payload
): Promise<boolean> => {
  return (await logout()).success;
};
