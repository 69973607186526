import { MobileEventHandler } from "./MobileWrapperEventHandler";

import { nativeAlert } from "@tef-novum/webview-bridge";

/**
 * Handler for
 * @param payload @see CustomerBarredEvent
 * @returns Promise<void>
 */
export const handleCustomerBarred: MobileEventHandler<
  "customer-barred"
> = async (payload) => {
  console.log("handleCustomerBarred", payload);

  return nativeAlert({
    message: payload.message,
    buttonText: payload.buttonText,
  });
};
