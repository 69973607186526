import { updateNavigationBar } from "@tef-novum/webview-bridge";
import { MobileEventHandler } from "./MobileWrapperEventHandler";

/**
 * Handler für @see SetTitleEvent
 * @param evt SetTitleEvent
 * @returns Promise<void>
 */
export const handleSetTitle: MobileEventHandler<"set-title"> = async (
  payload
) => {
  console.log("handleSetTitle", payload);
  return updateNavigationBar({ title: payload.title });
};
