import { MobileEventHandler } from "./MobileWrapperEventHandler";
import { openOnboarding } from "@tef-novum/webview-bridge";

/**
 * Handler for @see OpenOnboardingEvent
 * @param payload OpenOnboardingEvent
 * @returns Promise<void>
 */
export const handleOpenOnboarding: MobileEventHandler<
  "open-onboarding"
> = async (payload) => {
  console.log("handleOpenOnboarding", payload);

  return openOnboarding();
};
