import { MobileEventHandler } from "./MobileWrapperEventHandler";
import { updateNavigationBar } from "@tef-novum/webview-bridge";

/**
 * Handler for @see CartUpdatedEvent
 * @param payload
 * @returns Promise<void>
 */
export const handleCartUpdated: MobileEventHandler<"cart-updated"> = async (
  payload
) => {
  console.log("handleCartUpdated", payload);

  return updateNavigationBar({
    rightActions: [
      {
        name: "shopping-cart",
        id: "shopping-cart", // <- just guessed this. missing in requirements
        badge: { show: true, number: payload.noOfItemsInCart },
        icon: {
          url: payload.iconUrl,
          urlDark: payload.iconUrlDark,
        },
      },
    ],
  });
};
