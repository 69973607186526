import { MobileEventHandler } from "./MobileWrapperEventHandler";

import { dismiss } from "@tef-novum/webview-bridge";

/**
 * Handler for
 * @param payload @see DismissEvent
 * @returns Promise<void>
 */
export const handleDismissEvent: MobileEventHandler<
  "dismiss-trigger"
> = async (payload) => {
  console.log("handleDismissEvent", payload);
  //return downloadBase64({contentInBase64: string; fileName: string}) => Promise<void>;
return await dismiss(
  payload.onCompletionUrl,
  ).then(res =>{
    console.log("if dismiss success", res);
  }).catch(err=>{
    console.log("if dismiss error",err);
  });
};
