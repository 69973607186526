import { emit } from "./emit";
// import { addEventListener } from "./novumNativeEventsHandler";

// export const setupEventBus = () => {
//   window.tefMobileWrapper = {
//     emit,
//     addEventListener,
//   };
// };

export const eventBusFunctions = {
  emit,
  // addEventListener,
};
