import { registerEventListeners } from "./events";
import { eventBusFunctions } from "./bus";
import { deviceInfo } from "./deviceInfo";

if (!window.tefMobileWrapper) {
  registerEventListeners();
  window.tefMobileWrapper = {
    ...eventBusFunctions,
    device: deviceInfo,
  };
}
